<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <h3>REPORTES</h3>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item> Reportes </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>

    <template>
      <v-card class="overflow-hidden mt-2">
        <v-toolbar flat color="white">
          <v-toolbar-title class="font-weight-bold" color="grey darken-3">
            REPORTE GRÁFICO
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>

        <v-card-text>
          <v-row class="mx-0">
            <v-row>
              <v-col cols="12" sm="12" lg="3" md="3">
                <span class="green--text">Tipología de eventos</span>

                <div class="container-filter">
                  <v-icon color="grey">mdi-format-list-bulleted</v-icon>
                  <v-autocomplete
                    :items="event_types"
                    item-text="name"
                    item-value="id"
                    single
                    label="Tipo de eventos"
                    v-model="filters.event_type_id"
                    @change="changeEventTypes"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="12" sm="12" lg="3" md="3">
                <span class="green--text"
                  >Rango fecha de consulta - Inicio</span
                >

                <v-menu
                  ref="minDatePicker"
                  v-model="minDatePicker"
                  :close-on-content-click="false"
                  :return-value.sync="filters.min_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filters.min_date"
                      label="Rango de fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="handleClearMinDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filters.min_date"
                    no-title
                    scrollable
                    :max="filters.max_date"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="minDatePicker = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="handleSetMinDate">
                      Aplicar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" lg="3" md="3">
                <span class="green--text">Rango fecha de consulta - Fin</span>

                <v-menu
                  ref="maxDatePicker"
                  v-model="maxDatePicker"
                  :close-on-content-click="false"
                  :return-value.sync="filters.max_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filters.max_date"
                      label="Rango de fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="handleClearMaxDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filters.max_date"
                    no-title
                    scrollable
                    :min="filters.min_date"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="maxDatePicker = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="handleSetMaxDate">
                      Aplicar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>

        <template v-if="currentEventType && currentEventType.id">
          <v-card-text>
            <v-row class="mt-2">
              <v-col md="6" lg="6" sm="12" xs="12">
                <v-card>
                  <v-card-text class="">
                    <h3
                      class="title blue-grey--text text--darken-2 font-weight-regular text-uppercase"
                    >
                      {{ currentEventType.name }} POR ZONA
                    </h3>
                    <h6 class="subtitle-2 font-weight-light"></h6>
                    <div class="">
                      <typology-by-area
                        :queryStats="filters"
                      ></typology-by-area>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="6" lg="6" sm="12" xs="12">
                <v-card>
                  <v-card-text class="">
                    <h3
                      class="title blue-grey--text text--darken-2 font-weight-regular text-uppercase"
                    >
                      {{ currentEventType.name }} POR GENERO
                    </h3>
                    <h6 class="subtitle-2 font-weight-light"></h6>
                    <div class="" max-height="100">
                      <typology-by-genre
                        :queryStats="filters"
                      ></typology-by-genre>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col md="6" lg="6" sm="12" xs="12">
                <v-card>
                  <v-card-text class="">
                    <h3
                      class="title blue-grey--text text--darken-2 font-weight-regular text-uppercase"
                    >
                      {{ currentEventType.name }} POR GRUPOS ETARIOS
                    </h3>
                    <h6 class="subtitle-2 font-weight-light"></h6>
                    <div class="" max-height="100">
                      <typology-by-age-groups
                        :queryStats="filters"
                      ></typology-by-age-groups>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="6" lg="6" sm="12" xs="12">
                <v-card>
                  <v-card-text class="">
                    <h3
                      class="title blue-grey--text text--darken-2 font-weight-regular text-uppercase"
                    >
                      {{ currentEventType.name }} POR TERRITORIOS
                    </h3>
                    <h6 class="subtitle-2 font-weight-light"></h6>
                    <div class="" max-height="100">
                      <typology-by-territories
                        :queryStats="filters"
                      ></typology-by-territories>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col md="12" lg="12" sm="12" xs="12">
                <v-card>
                  <v-card-text class="">
                    <h3
                      class="title blue-grey--text text--darken-2 font-weight-regular text-uppercase"
                    >
                      {{ currentEventType.name }} POR MUNICIPIOS
                    </h3>
                    <h6 class="subtitle-2 font-weight-light"></h6>
                    <div class="" max-height="100">
                      <typology-by-municipalities-maps
                        :queryStats="filters"
                      ></typology-by-municipalities-maps>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-card>

      <v-card class="overflow-hidden mt-2">
        <v-toolbar flat color="white">
          <v-toolbar-title class="font-weight-bold" color="grey darken-3">
            GENERAR REPORTE EXCEL
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="mt-2">
            <v-col md="12" lg="12" sm="12" xs="12">
              <v-card>
                <v-card-text class="">
                  <div class="" max-height="100">
                    <list-cases :queryStats="filters"></list-cases>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import sweetalert from "sweetalert2";

import TypologyByArea from "./reports/TypologyByArea.vue";
import TypologyByGenre from "./reports/TypologyByGenre.vue";
import TypologyByAgeGroups from "./reports/TypologyByAgeGroups.vue";
import TypologyByTerritories from "./reports/TypologyByTerritories.vue";
import TypologyByMunicipalitiesMaps from "./reports/TypologyByMunicipalitiesMaps.vue";
import ListCases from "./reports/ListCases.vue";

import { parameterization } from "@/services/graphicReports/humansServices";

export default {
  components: {
    TypologyByArea,
    TypologyByGenre,
    TypologyByAgeGroups,
    TypologyByTerritories,
    TypologyByMunicipalitiesMaps,
    ListCases,
  },
  name: "graphicReports.vue",
  data() {
    return {
      currentEventType: {},
      event_types: [],
      filters: {
        min_date: "",
        max_date: "",
        event_type_id: 0,
      },
      minDatePicker: false,
      maxDatePicker: false,
    };
  },
  methods: {
    async fillData() {
      this.showLoader();
      const { data } = await parameterization();
      this.event_types = data.event_types || [];
      this.hideLoader();
    },
    changeEventTypes(event) {
      this.currentEventType = this.event_types.find(
        (item) => item.id === event
      );
      const auxCurrentEventType = this.currentEventType;
      this.currentEventType = {};

      this.$nextTick(() => {
        this.currentEventType = auxCurrentEventType;
      });
    },
    handleClearMinDate() {
      this.filters.min_date = '';
      this.handleReloadReports();
    },
    handleClearMaxDate() {
      this.filters.max_date = '';
      this.handleReloadReports();
    },
    handleSetMinDate() {
      this.$refs.minDatePicker.save(this.filters.min_date);
      this.handleReloadReports();
    },
    handleSetMaxDate() {
      this.$refs.maxDatePicker.save(this.filters.max_date);
      this.handleReloadReports();
    },
    handleReloadReports() {
      const auxCurrentEventType = this.currentEventType;
      this.currentEventType = {};

      this.$nextTick(() => {
        this.currentEventType = auxCurrentEventType;
      });
    }
  },
  async created() {
    try {
      await this.fillData();
    } catch (err) {
      this.hideLoader();
      sweetalert.fire(
        "Ups!",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
};
</script>

<style scoped>
.container-filter {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
