import baseService from "../baseService";
const resource = "/rest/v1";

export function typologyByArea(data) {
  return baseService.post(
    `${resource}/graphic_reports/humans/typology-by-area`,
    data
  );
}

export function typologyGenre(data) {
  return baseService.post(
    `${resource}/graphic_reports/humans/typology-genre`,
    data
  );
}

export function typologyByAgeGroups(data) {
  return baseService.post(
    `${resource}/graphic_reports/humans/typology-by-age-groups`,
    data
  );
}

export function typologyByTerritories(data) {
  return baseService.post(
    `${resource}/graphic_reports/humans/typology-by-territories`,
    data
  );
}

export function typologyByMunicipalitiesMaps(data) {
  return baseService.post(
    `${resource}/graphic_reports/humans/typology-by-municipalities-maps`,
    data
  );
}

export function listCases(data) {
  return baseService.post(
    `${resource}/graphic_reports/humans/list-cases`,
    data
  );
}

export async function listCasesDonwload(data) {
  return baseService.post(
    `${resource}/graphic_reports/humans/list-cases-download`,
    data,
    {
      responseType: "blob",
    }
  );
}

export function parameterization() {
  return baseService.get(`${resource}/graphic_reports/humans/parameterization`);
}
