<template>
  <main>
    <div class="wrapper">
      <canvas id="typologyByArea"></canvas>
    </div>
  </main>
</template>
<script>
import Chart from "chart.js/auto";
import { typologyByArea } from "../../../../services/graphicReports/humansServices";

export default {
  props: ["queryStats"],
  data() {
    return {
      reportData: [],
    };
  },
  methods: {
    async loadReportData() {
      const { data } = await typologyByArea(this.queryStats);
      this.reportData = data;

      this.$nextTick(() => this.renderBar());
    },

    renderBar() {
      const typologyByArea_bar = document.getElementById("typologyByArea");

      let label = [];
      let datos = [];

      this.reportData.forEach((item, position) => {
        label[position] = item.zone__descripcion || "";
        datos[position] = item.total || 0;
      });

      new Chart(typologyByArea_bar, {
        type: "bar",
        data: {
          labels: label,
          datasets: [
            {
              label: "DATOS",
              data: datos,
              fill: false,
              backgroundColor: ["rgb(67, 121, 78, 1)"],
              borderColor: "rgb(0, 128, 55,0.2)",
              pointBackgroundColor: "rgb(0, 128, 55)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgb(0, 128, 0)",
            },
          ],
        },
        options: {
          indexAxis: "y",
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              borderWidth: 2,
            },
          },
        },
      });
    },
  },
  beforeMount() {
    this.loadReportData();
  },
};
</script>
<style scoped>
.wrapper {
  width: 100%;
  height: 450px;
  max-height: 650px;
  text-align: center !important;
}
</style>
